import React, { useEffect } from 'react';
import Head from 'next/head';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';
import { initGA, logPageView } from '@/lib/util/googleAnalytics';
import LogInHeader from '../../dashboardpages/LogInHeader';
import Header from '../widgets/Header';

export default function Layout(props) {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();

    // adding hotjar
    hotjar.initialize(1803190, 6);

    window.addEventListener('scroll', handleScroll);

    // adding intercom
    window.intercomSettings = {
      app_id: "r0k928f8"
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/r0k928f8'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r0k928f8';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  
  }, []);
  const handleScroll = (event) => {
    const delayInMilliseconds = 0.5; // 1 second

    const { scrollTop } = event.srcElement.body;
    const itemTranslate = Math.min(0, scrollTop / 3 - 60);
    // if (window.scrollY > 150 && window.scrollY < 300) {
    //   document.getElementById("light-header").id = "header";

    // }
    if (window.scrollY > 300) {
      if (document.getElementById('light-header') != null) {
        document.getElementById('light-header').id = 'header';
        // document.getElementById("header").style.animation = "nav-in .5s forwards";
      }
      // document.getElementsByClassName("navbar-light").id = "header";
    } else if (document.getElementById('header') != null) {
      // document.getElementById("header").style.animation = "nav-out .5s forwards";
      // setTimeout(function() {
      // }, delayInMilliseconds);
      document.getElementById('header').id = 'light-header';
    }
  };
  
  return (
    <>
      <Head>
        <title>Big & Mini</title>
        <link rel="icon" href="favicon_io/favicon.ico" />
        <link rel="apple-touch-icon" href="favicon_io/apple-touch-icon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta charSet="utf-8" />
      </Head>
      <Header />
      
      {props.children}
    </>
  );
}
